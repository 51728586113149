<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Order Verification",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Order Verification",
      items: [],
      value: null,
      autoClose: "",
      valueconsfind: null,
      options_consfind: [
      ],
      valuegoodstype: null,
      options_goodstype: [
        "General Cargo",
        "Perishable",
        "Live Fish/Benur",
      ],
      options_vendor: [
        "Air Asia",
        "Lion Air",
        "Tri-MG",
        "Trigana", "Hercules"
      ],
      options_port: [
        "CGK - Soekarno-Hatta, Banten",
        "HLP – Halim Perdanakusuma, Jakarta",
        "JOG – Yogyakarta",
        "PLM – Palembang",
        "SOC – Solo",
        "SUB – Surabaya"
      ],
      options_flight: [
        "GM705 12:00-14:30 Tri-MG"
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order/SMU No"
                    label-for="customer_code"
                  >
                    <b-form-input class="col-sm-12 col-lg-9" id="customer_code" name="customer_code" value=""></b-form-input>
                    
                  <b-button variant="primary" class="col-sm-2 col-lg-2">
                    <i class="ri-search-line"></i>
                    Submit
                  </b-button>

                  </b-form-group>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
<div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                Tri-MG <br/>
                Departure 22 Februari 2021
              </div>
              <div class="col-xl-3 text-center">
                12:50 <i class="ri-arrow-right-line"></i> 09:25(+1d)<br/>
                Jakarta(CGK) <i class="ri-arrow-right-line"></i> Jayapura(DJJ)
              </div>
              <div class="col-xl-3 text-center">
                18h 35m<br/>
                2x Transit
              </div>
              <div class="col-xl-3 text-center">
                <h5>Rp 27.000/kg</h5>
              </div>               
            </div>
            <br/>
            <div class="row">
              <div class="col-12">
              Notes : Goods must be in <u>Gudang RA - CGU</u> Halim Perdanakusuma Airport a <u>
                maximum of 3 hours before departure time</u>
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <div class="table-responsive" style="height:250px">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th width="180">Goods Type<span class="text-danger">*</span></th>
                          <th>Collies<span class="text-danger">*</span></th>
                          <th>Weight Per Collie<span class="text-danger">*</span></th>
                          <th>Width<span class="text-danger">*</span></th>
                          <th>Height<span class="text-danger">*</span></th>
                          <th>Length<span class="text-danger">*</span></th>
                          <th>Volume Per Collie</th>
                          <th>Total Chargeable Weight</th>
                          <th>Remarks</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row"><b-form-input value=""></b-form-input></th>
                          <td style=""><multiselect id="" name="" v-model="valuegoodstype" :options="options_goodstype"></multiselect></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td></td><td></td>
                          <td><b-form-input value=""></b-form-input></td>
                          <td>
                            <b-button size="sm" variant="primary">
                              <i class="ri-save-line"></i>
                            </b-button>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">1</th>
                          <td>General Cargo</td>
                          <td class="text-right">3</td>
                          <td class="text-right">26,67</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <b-button size="sm" variant="danger">
                              <i class="ri-delete-bin-line"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label>Shipper Name</label><br />
                  <b-form-input id="customer_name" name="customer_name" value="Company Name"></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Shipper Address</label><br />
                  <b-form-input id="customer_name" name="customer_name" value=""></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Shipper Phone</label><br />
                  <b-form-input id="customer_name" name="customer_name" value=""></b-form-input>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label>Find Consignee</label><br />
                  <multiselect id="" name="" v-model="valueconsfind" :options="options_consfind"></multiselect>
                </div>
                <div class="form-group mb-3">
                  <label>Consignee Name</label><br />
                  <b-form-input id="customer_name" name="customer_name" value=""></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Consignee Address</label><br />
                  <b-form-input id="customer_name" name="customer_name" value=""></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Consignee Phone</label><br />
                  <b-form-input id="customer_name" name="customer_name" value=""></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">New Data</h4>
            <form class="form-horizontal" role="form">

              <b-form-group
                label-cols-sm="5"
                label-cols-lg="5"
                label="Total Chargeable Weight"
                label-for=""
              >
                <div class="pt-2"><h6>: &nbsp; 80</h6></div>
              </b-form-group>

              <b-form-group
                label-cols-sm="5"
                label-cols-lg="5"
                label="Grand Total Charge"
                label-for=""
              >
                <div class="pt-2"><h6>: &nbsp; 2.000.000</h6></div>
              </b-form-group>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Previous Data</h4>
            <form class="form-horizontal" role="form">
              <b-form-group
                label-cols-sm="5"
                label-cols-lg="5"
                label="Total Chargeable Weight"
                label-for=""
              >
                <div class="pt-2"><h6>: &nbsp; 80</h6></div>
              </b-form-group>

              <b-form-group
                label-cols-sm="5"
                label-cols-lg="5"
                label="Grand Total Charge"
                label-for=""
              >
                <div class="pt-2"><h6>: &nbsp; 2.000.000</h6></div>
              </b-form-group>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>